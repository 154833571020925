


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputSecondSurname extends Vue {
    @Prop({ type: String }) value!: string;

    get currentValue() {
        return this.value;
    }

    set currentValue(value: string) {
        this.$emit('input', value);
    }
}
