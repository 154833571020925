



























import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import FormPerson from '@/components/FormPerson.vue';
import Swal from 'sweetalert2';
import { User } from '@/interfaces/user';

@Component({
    components: { FormPerson }
})
export default class PersonalInformation extends Vue {
    @Ref() formPerson!: FormPerson;
    redirectTo: string | null = null;
    form: Partial<User> = {};

    async store() {
        try {
            await agentModule.editAgent({
                email: this.form.email,
                nombres: this.form.nombres,
                apellidoPaterno: this.form.apellidoPaterno,
                apellidoMaterno: this.form.apellidoMaterno,
                typoDoc: this.form.typoDoc,
                nroDoc: this.form.nroDoc,
                telefono1: this.form.telefono1,
                anexo: this.form.anexo,
                celular: this.form.celular,
                sexo: this.form.sexo
            });
            Swal.fire({
                title: 'Éxito',
                text: 'Se actualizaron los datos correctamente.',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
                width: '480px'
            }).then(() => {
                if (this.redirectTo) {
                    this.$router.push({ name: this.redirectTo });
                }
            });
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }

    get informationIsMissing() {
        return agentModule.informationIsMissing;
    }

    @Watch('informationIsMissing', { deep: true, immediate: true })
    redirect(value: boolean, oldValue: boolean) {
        if (oldValue || value) {
            this.redirectTo = 'MissingInformation';
        }
    }

    async mounted() {
        this.form = {
            email: agentModule.agent?.email,
            nombres: agentModule.agent?.nombres,
            apellidoPaterno: agentModule.agent?.apellidoPaterno,
            apellidoMaterno: agentModule.agent?.apellidoMaterno,
            typoDoc: agentModule.agent?.typoDoc,
            nroDoc: agentModule.agent?.nroDoc,
            telefono1: agentModule.agent?.telefono1,
            anexo: agentModule.agent?.anexo,
            celular: agentModule.agent?.celular,
            sexo: agentModule.agent?.sexo,
            avatar: agentModule.agent?.avatar
        };
    }
}
