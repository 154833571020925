



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputCellPhone extends Vue {
    @Prop({ type: String }) value!: string;
    @Prop({
        type: Object,
        default: () => ({
            required: true,
            numeric: true,
            min: 9,
            max: 9
        })
    })
    rules!: object;

    get currentValue() {
        return this.value;
    }

    set currentValue(value: string) {
        this.$emit('input', value);
    }
}
