



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SelectDocument extends Vue {
    @Prop({ type: String }) value!: string;
    @Prop({ type: String }) vid!: string;
    @Prop({ type: [String, Object] }) rules!: string | object;
    @Prop({ type: String }) name!: string;
    @Prop({ type: String, default: 'w-100' }) classSelect!: string;

    created() {
        if(!this.value) this.$emit('input', 'dni');
    }

    get currentValue() {
        return this.value;
    }

    set currentValue(value: string) {
        this.$emit('input', value);
    }

    options = [
        {
            value: 'dni',
            label: 'DNI'
        },
        {
            value: 'pas',
            label: 'PAS'
        },
        {
            value: 'ce',
            label: 'C.E.'
        }
    ];
}
