import { Vue } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';

@Component
export default class DocumentMixin extends Vue {
    maxlengthDocument: number | null = 8;

    changeDocumentNumber(data: {
        maxlengthDocument: number | null;
        value: string;
    }): string {
        if (!data.maxlengthDocument) return data.value;
        let regex = new RegExp('^[0-9]*$');
        switch (data.maxlengthDocument) {
            case 8:
                regex = new RegExp('^[0-9]*$');
                break;
            case 11:
                regex = new RegExp('^[0-9]*$');
                break;
            case 12:
                regex = new RegExp('^[a-zA-Z0-9_.-]*$');
                break;
        }
        if (data.value && !regex.test(data.value)) {
            return data.value.substring(0, data.value.length - 1);
        }
        return data.value;
    }

    changeDocumentType(value: string): number | null {
        let returnValue = null;
        switch (value) {
            case 'dni':
                returnValue = 8;
                break;
            case 'pas':
                returnValue = 12;
                break;
            case 'ce':
                returnValue = 12;
                break;
        }
        return returnValue;
    }
}
