






























































































































import { Component, Mixins, PropSync, Ref } from 'vue-property-decorator';
import CropperUpload from '@/components/CropperUpload.vue';
import DocumentMixin from '@/mixins/document';
import { Person } from '@/interfaces/person';
import { ValidationObserver } from 'vee-validate';
import InputName from '@/components/FormPerson/InputName.vue';
import InputFirstSurname from '@/components/FormPerson/InputFirstSurname.vue';
import InputSecondSurname from '@/components/FormPerson/InputSecondSurname.vue';
import InputSex from '@/components/FormPerson/InputSex.vue';
import InputPhone from '@/components/FormPerson/InputPhone.vue';
import InputCellPhone from '@/components/FormPerson/InputCellPhone.vue';
import InputEmail from '@/components/FormPerson/InputEmail.vue';
import InputAnexo from '@/components/FormPerson/InputAnexo.vue';
import SelectDocument from '@/components/SelectDocument.vue';
import { agentModule } from '@/store/modules/agent';

@Component({
    components: {
        CropperUpload,
        InputName,
        InputFirstSurname,
        InputSecondSurname,
        InputPhone,
        InputCellPhone,
        InputEmail,
        InputSex,
        SelectDocument,
        InputAnexo
    }
})
export default class FormPerson extends Mixins(DocumentMixin) {
    @Ref() observer!: InstanceType<typeof ValidationObserver>;
    @PropSync('data', { required: true }) form!: Partial<Person> & {
        avatar?: string;
    };
    minlengthDocument = 8;

    async upload(form: FormData) {
        form.append('key', 'avatar');
        await agentModule.uploadFile(form);
        this.form.avatar = agentModule.agent?.avatar;
    }

    reset() {
        this.form = {};
        this.observer.reset();
    }

    handleDocumentType(value: string): void {
        this.maxlengthDocument = value === 'dni' ? 8 : 200;
        this.minlengthDocument = value === 'dni' ? 8 : 7;
        this.form.nroDoc = '';
    }

    handleDocumentNumber(value: string) {
        this.form.nroDoc = this.changeDocumentNumber({
            value,
            maxlengthDocument: this.maxlengthDocument
        });
    }
}
