











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputEmail extends Vue {
    @Prop({ type: String }) value!: string;
    @Prop({ type: Boolean }) disabled!: boolean;
    @Prop({
        type: Object,
        default: () => ({
            required: true,
            email: true
        })
    })
    rules!: object;

    get currentValue() {
        return this.value;
    }

    set currentValue(value: string) {
        this.$emit('input', value);
    }
}
