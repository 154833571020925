















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputName extends Vue {
    @Prop({ type: String }) value!: string;
    @Prop({ type: String, default: 'required|alpha_spaces' }) rules!: string;

    get currentValue() {
        return this.value;
    }

    set currentValue(value: string) {
        this.$emit('input', value);
    }
}
